<template>
  <div class="row pt-3">
    <div class="col-xl-12 text-center">
      <div class="row pt-3 pb-3">
        <div class="col-xl-12">
          <img src="media/wx/wx.jpg" class="max-h-300px" alt="" />
        </div>
      </div>
      <div class="row pt-3 pb-3">
        <div class="col-xl-12">
          使用微信扫一扫关注科易博软件微信公众号，可使用科易博小助手进行登录
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$emit('send', "3");
  }
}
</script>